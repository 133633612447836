import React, { Component } from 'react'

const ProductOptions = class extends Component {
  componentDidMount = () => {    
    this.configureComponent();

    window.addEventListener('resize', this.configureComponent)
  }

  configureComponent = () => {
    const optionsElement = document.getElementById('options')

    if (window.matchMedia("(max-width: 719px)").matches) {
      optionsElement.removeAttribute('style')
    } else {
      const 
          summaryBlocks = optionsElement.querySelectorAll('div.story')
        , activeBlock = optionsElement.querySelector('div.active')
        , listItems = optionsElement.querySelectorAll('.__opts-list a')
      let height = 0

      summaryBlocks.forEach((item) => {
        if (height < item.clientHeight) {
          height = item.clientHeight  
        }
      })

      optionsElement.style.height = `${height}px`

      if (!activeBlock) {
        summaryBlocks[0].classList.add('active')
        listItems[0].classList.add('active')
      }
    }
  }

  changeOptionHandler = (event) => {
    event.preventDefault()    

    const 
        optionId = event.target.href.split('#')[1]
      , option = document.getElementById(optionId)
      , current = document.querySelector('#options .story.active')
      , currentLink = document.querySelector('.__opts-list a.active')

      current.classList.remove('active')
      currentLink.classList.remove('active')
      option.classList.add('active')
      event.target.classList.add('active')
  }

  render() {
    const { children } = this.props

    return (     
      <div id="options">
        <div className="__opts-list">
          <ul>
            <li>
              <a href="#material" onClick={this.changeOptionHandler}>
                Timbers
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                  <polygon points="192.18 207.76 192.19 207.76 192.19 207.76 192.18 207.76"/>
                  <polygon points="307.82 207.76 307.73 207.76 307.77 207.83 307.82 207.76"/>
                  <path d="M250,0C111.93,0,0,111.93,0,250S111.93,500,250,500,500,388.07,500,250,388.07,0,250,0ZM367.62,349.15v52h-36V360.49H271.08v61H228.83v-61H168.41v40.66h-36v-52H53.89l47.29-76.76H65.28l43.33-64.63H90l60.17-101.89v-.63l.19.32.18-.32v.63l40.52,68.61L249.74,75.2v-.74l.22.37.21-.37v.74l58.68,99.36,40.57-68.69v-.63l.18.32.19-.32v.63L410,207.76H391.39l43.33,64.63h-35.9l47.29,76.76Z"/>
                </svg>
              </a>
            </li>
            {/* <li>
              <a href="#style" onClick={this.changeOptionHandler}>
                Stiles &amp; Designs
                <svg viewBox="0 0 500 500">
                  <circle cx="250" cy="250" r="250" />
                </svg>
              </a>
            </li> */}
            <li>
              <a href="#ironmongery" onClick={this.changeOptionHandler}>
                Ironmongery
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                  <path d="M326.62,275.63a24.3,24.3,0,0,0-14.71,43.65v20.51a14.72,14.72,0,1,0,29.43,0V319.28a24.3,24.3,0,0,0-14.72-43.65Z"/>
                  <path d="M250,0C111.93,0,0,111.93,0,250S111.93,500,250,500,500,388.07,500,250,388.07,0,250,0ZM119,218.73a17.17,17.17,0,0,1-17.12-17.13v-21A17.17,17.17,0,0,1,119,163.5H336.27a17.18,17.18,0,0,1,17.13,17.13v21a17.18,17.18,0,0,1-17.13,17.13Zm279.16,162a35.58,35.58,0,0,1-35.47,35.48H288.09a35.58,35.58,0,0,1-35.47-35.48V236.06h72.76a45.09,45.09,0,0,0,44.95-44.95h0a45.08,45.08,0,0,0-44.95-44.94H252.62V119.23a35.58,35.58,0,0,1,35.47-35.48h74.58a35.58,35.58,0,0,1,35.47,35.48Z"/>
                </svg>
              </a>
            </li>
            {/* <li>
              <a href="#finish" onClick={this.changeOptionHandler}>
                Finishes
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                  <circle cx="100.1" cy="43.79" r="5.08"/>
                  <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0Zm39.62,146.08s-.41,12.72-8.16,17.21H52.33c7.75-4.49,8.16-17.21,8.16-17.21V116.3h79.13Zm0-38.1H60.54V93.87a5.35,5.35,0,0,1,3.1-4.08c2.81-1.27,18.84-6.61,18.84-6.61s6.35-.71,7.43-12.08C91.25,56.83,86,51.48,86.24,41.57,86.5,28.7,100.1,28.71,100.1,28.71s13.61,0,13.87,12.86c.21,9.91-5,15.26-3.67,29.53,1.07,11.37,7.43,12.08,7.43,12.08s16,5.34,18.84,6.61a5.35,5.35,0,0,1,3.1,4.08Z"/>
                </svg>
              </a>
            </li>
            <li>
              <a href="#glass" onClick={this.changeOptionHandler}>
                Glass
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                  <path d="M250,0C111.93,0,0,111.93,0,250S111.93,500,250,500,500,388.07,500,250,388.07,0,250,0ZM148.73,414.12H101V85.88h47.75Zm63.67,0H164.65V85.88H212.4ZM399,389.77h-84v-1l29.09-29.09L258,273.47l-.1.1L233.1,248.81,373.39,108.52l24.77,24.76L282.72,248.71l86.18,86.18L398,305.8h1Z"/>
                </svg>
              </a>
            </li> */}
          </ul>
        </div>
        <div className="__opts-summary">
          { children }
        </div>
      </div>
    )
  }
}

export default ProductOptions