import React from 'react'

const HeroBar = ( props ) => (
  <div className="hero-bar">
    <div
      style={{ backgroundImage: `url(/static/images/${props.imageName}.jpg)` }}
    />
  </div>
)

export default HeroBar