import React from 'react'

const Wood = (props) => (
  <div id={props.Id} className="story">
    <h3>Choice of wood</h3>
    <p>
      The <strong>{props.ProductName}</strong> can be manufactured from our standard range of softwood or hardwood providing flexibility to meet your project requirements.
    </p>
    <ul className="samples">
      <li title="Redwood">
        <img src="/static/images/redwood.jpg" alt="Redwood" />
      </li>      
      <li title="European oak">
        <img src="/static/images/oak-european.jpg" alt="European oak" />
      </li>
      <li title="Tulip">
        <img src="/static/images/tulip.jpg" alt="Tulip" />
      </li>
    </ul>
</div>
)

export default Wood