import React from 'react'

const Wood = (props) => (
  <div id={props.Id} className="story">
    <h3>Choice of ironmongery</h3>
    <p>
      With a large selection of hardware from our standard range, you can tailor the <strong>{props.ProductName}</strong> to match your style and needs.
    </p>
    {/* <ul className="samples">
      <li>
        [image]
      </li>
      <li>
        [image]
      </li>
      <li>
        [image]
      </li>
      <li>
        [image]
      </li>
      <li>
        [image]
      </li>
      <li>
        [image]
      </li>
    </ul> */}
</div>
)

export default Wood