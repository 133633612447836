import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options.2'
import ProductFeatures from '../../components/product-features'

import Wood from '../../components/product-options-wood-beadbutt'
import Ironmongery from '../../components/product-options-ironmongery'

import ProductHero from './bead-butt-door.jpg'

const BeadButt = () => (
  <Layout>
    <Helmet title="Bead & Butt Doors | Traditional styling | Rudd Joinery">
      <meta name="description" content="A popular choice for period properties as well as refurbishment projects and new builds, our Norfolk Bead and Butt doors are a traditional style of door that can add a timeless touch to a property." />      
    </Helmet>
    <Hero heroImage="bead-and-butt" heroId="hero" nextId="description">
      <h1>Bead &amp; butt</h1>
      <h2>Traditional styling</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            A popular choice for period properties as well as refurbishment
            projects and new builds, our Norfolk <strong>Bead and Butt</strong>{' '}
            doors are a traditional style of door that can add a timeless touch
            to a property. Available in a range of timbers, including pine and
            oak as well as other species, our bead and butt doors have 21mm
            thick boards with a 24mm thick ledge as standard.
          </p>
          <p>
            As well as choosing the timber species, you can choose from a range
            of sizes, both standard and bespoke as well as Suffolk latches and
            Tee hinges for a truly authentic look with a local feel.
          </p>
          <p>
            <img 
              src={ProductHero} 
              alt="Rudd Joinery | Bead and Butt Door" 
              className="product-hero"
              data-sal-duration="600"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out-bounce"
            />
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky ovalroomblue">
        <div className="expanded">
          <h3>Our bead &amp; butt features</h3>
          <ul>
            <li>Traditional style of door</li>
            <li>Bespoke and made to order</li>
          </ul>
        </div>
      </div>
      {/* <div className="feature-bar">
        <div className="block c__1" />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1" />
      </div> */}
      <div className="cta-bar ovalroomblue">
        <div className="expanded">
          <p>
            Our <strong>Bead &amp; Butt</strong> doors can be installed
            alongside our other windows and doors, as all our mouldings are
            complimented across our product range
          </p>
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="bead-and-butt" />
    <div className="section ovalroomblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Bead &amp; butt options</h2>
        </div>
        <ProductOptions ProductName="Bead &amp; butt">
          <Wood Id="material" ProductName="Bead &amp; Butt" />
          <Ironmongery Id="ironmongery" ProductName="Bead &amp; Butt" />
        </ProductOptions>
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Bead &amp; butt</h2>
          <p>
            Our bespoke <strong>Bead &amp; Butt</strong> doors can be manufactured
            to your specific requirements. If you need help, please do not
            hesitate to contact us on <strong>01485 529136</strong> or fill out
            our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default BeadButt