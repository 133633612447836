import React, { Component } from 'react'

const Features = class extends Component {
  componentDidMount = () => {
    window.addEventListener('scroll', this.FeatureScrollHandler)
  }

  FeatureScrollHandler = () => {
    const 
        featuresContainer = document.getElementById('features')
      , featuresBar = document.querySelector('.sticky')
      , featuresItems = featuresContainer.querySelectorAll('.feature-bar')
      , topHeader = document.getElementById('page-header')
      , pageYOffset = window.pageYOffset
    let 
        currentItem = 0

    for (var element of featuresItems) {
      if ((element.offsetTop + element.clientHeight) >= ((pageYOffset + featuresBar.clientHeight) - topHeader.clientHeight)) {         
        break
      } else {
        currentItem++
      }
    }

    if (featuresBar.querySelector('li.current'))
      featuresBar.querySelector('li.current').classList.remove('current')
    if (featuresBar.querySelectorAll('li')[currentItem])
      featuresBar.querySelectorAll('li')[currentItem].classList.add('current')
  }

  render() {
    const { children } = this.props

    return (
      <div id="features">
        { children }
      </div>
    )
  }
}

export default Features